.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.buttonUpperTitle {
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* or 112% */

  color: #4a4a4a;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
}
.gridItem {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 10px;
  font-size: 20px;
  text-align: center;
}
.heading {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
}

.subheading {
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
}

.price {
  padding: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0px;
}

.quantity {
  padding: 10px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
}
