.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 11px 24px 0 24px;
  }
  
  .form {
    flex-grow: 1;
  }
  
  .title {
    margin-bottom: 19px;
  
    @media (--viewportLarge) {
      margin-bottom: 0px;
      padding: 1px 0 7px 0;
    }
  }
  
  .aboutTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .listingCards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .listingCard {
    width: 50%;
  }

  .titleAndButton {
    margin-left: 5%;
  }
  