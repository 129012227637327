@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  color: black !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 50px !important;
  margin-top: 0 !important;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;
  color: #333333;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.text {
  color: #484848;
}
