.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  padding-right: 40px !important;
}

.form {
  flex-grow: 1;
}

.h1 {
  margin-bottom: 2px;
}
.smallSpan {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}
