.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
  column-count: 2;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 5px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

@media only screen and (max-width: 600px) {
  .list {
    column-count: 1;
  }
}
