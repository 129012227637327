@import '../../styles/propertySets.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  @apply --marketplaceH4FontStyles;
  border-bottom-width: 0;

  /* Avoid text going behind the submit button */
  padding: 0px 25px 26px 37px;

  margin: 0;
  position: relative;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 52px);

  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 0px 0px 0px 30px;
    margin: 0;
    width: 100%;
  }
}

.attachment {
  position: absolute;
  top: -5px;
  left: 0;
}

.formIconContainer {
}

.iconContainer {
  cursor: pointer;
  text-align: right;
}

.inputTypeFile {
  display: none;
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.locationImage {
  width: 20px;
}

.showImage {
  width: 80px;
  margin-left: 20px;
}

.crossImage {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  right: 0;
  width: 28px;
}
.sendMessageContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 30px 0 0 0;
  }
}

.imageContainer {
  text-align: left;
  margin-left: -25px;
  margin-bottom: 15px;
  position: relative;
}
.crossIcon {
  position: absolute;
  left: 86px;
  top: -8px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  background-color: #fff;
  font-size: 23px;
  line-height: 18px;
  cursor: pointer;
}
