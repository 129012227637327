.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.linkStyle { 
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}

@media only screen and (max-width: 550px) {
  .fixedButton {
    width: 90%;
    position: fixed;
    top: 90%;
    margin: 0 auto;
  }
}

.link {
  text-decoration: underline;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-top: 0px;
  
  @media (--viewportMaxSmall) {
    text-align: center;
  }
}

.buttonUpperTitle {
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* or 112% */

  color: #4a4a4a;
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
  @media (--viewportMaxSmall) {
    /* padding-bottom: calc(2 / 3 * 100%); */ /* 3:2 Aspect Ratio */
    position: relative;
    bottom: 100px;
  }
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}
