@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceH4FontStyles;
  margin-top: 6px;
  margin-bottom: 12px;
  color: '#1e1e1e';
  overflow: hidden;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
  }
}

.locationCard {
  margin-top: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 80%;
  background-color: white;
  max-height: 210px;
  width: 563px;
  border-radius: 12px;
  padding: 2px 16px !important;
}

.locationCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.errorheading {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

.para {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
