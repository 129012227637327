@import '../../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  margin: 0 0 0 10px;
}

.css.itemValues {
  margin-right: 20px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  padding-top: 0px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.para {
  font-size: 13px;
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 17px 23px;
  border-radius: 6px;
  top: 100%;
  left: 50%;
  margin-left: -60px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  line-height: 25px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.image {
  margin-left: 5px;
}
