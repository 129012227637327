@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  /* display: flex;
  flex: 1;
  flex-direction: column; */

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 20px;
  }
}

.buttonUpperTitle {
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  /* or 112% */

  color: #4a4a4a;
}

.gridContainer {
  /* display: grid; */
  grid-template-columns: auto auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
  float: left;
}
.gridItem {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  /* padding: 10px; */
  font-size: 20px;
  text-align: center;
}
.heading {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0px !important;
  margin-bottom: 0px !important;
}

.subheading {
  padding: 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  /* margin-right: 120px; */
  /* padding-top: 5%; */
  cursor: pointer;
}

.subheading:hover {
  border: 1px solid;
}

.price {
  padding: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 0px;
}

.quantity {
  padding: 5px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
}

.gridItemMadeToMeasure {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  height: 30px !important;
  min-height: 48px !important;
  width: 195px;
  /* left: 545px;
  top: 517px; */
  border-radius: 3px;
  padding: 8px, 47px, 8px, 47px;
  margin-top: 8px;
  margin-bottom: 30px;
}

.row {
  display: flex;
}

.column {
  flex: calc((100% - 20px) / 2);
  padding: 10px;
  height: 100%;
}

@media screen and (max-width: 550px) {
  .buttonFixed {
    position: fixed;
    bottom: 0px;
    left: 9%;
    width: 80%;
    /* top: 80%; */
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
  }
}


.detailsContainerDesktop {
  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 100%;
    padding-bottom: 55px;

    /* Position */
    margin: 50px 0 24px 0;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 0%;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.detailsHeadings {
  @media (--viewportLarge) {
    display: block;
  }
}

.detailsTitle {
  margin-bottom: 10px;
  margin-top: 0px !important;

  @media (--viewportLarge) {
    margin-bottom: 9px;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0px;
  }
}

.roundedBtn {
  background-color: white; /* Green */
  border: 1px solid black;
  color: black;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 2px;
  cursor: pointer;
  width: 35px;
  border-radius: 50%;
}

.checkBoxInput {
  vertical-align: middle;
  width: 30px;
}

/* .container {
  display: flex;
  align-items: baseline;
  padding: 5px
} */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 22px;
  color: #1e1e1e;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 36px; */
  letter-spacing: 0px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 17px;
  width: 17px;
  position: absolute;
  /* Primary/ 400 */

  border: 2px solid #535353;
  border-radius: 1.5px;
  /* background-color: #eee; */
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkboxLabel {
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-left: 0px;
  margin-left: 0px;
  margin-top: 10px;
  font-size: 14px;
  padding: 0px;
  display: flex;
  align-items: flex-start;
}

.checkboxInput {
  width: 5% !important;
  margin-top: 4px !important;
  display: inline-block !important;
  margin-right: 4px !important;
}

.checkboxLabel {
  margin-left: 0px;
}

.image {
  object-fit: cover;
}

.wrapperContainer {
  display: flex;
  align-items: center;
}

.modalHeading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0px;
}

.padding {
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
}

.detailsContainerDesktop {
  box-sizing: border-box;
  box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 20%);
  margin-bottom: 20px;
  padding: 8px 20px;
  text-align: left;
}

.detailsContainerDesktop .noItem {
  color: #666;
  font-size: 14px;
  font-weight: 300;
}

@media only screen and (max-width: 600px) {
  .checkmark {
    top: 2px;
  }
  .row {
    display: block;
  }
  .checkboxInput {
    width: 30px !important;
    height: 19px;
  }
}
