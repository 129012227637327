@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 11px 24px 0 24px;

    @media (--viewportMaxSmall) {
      height: 100vh;
      overflow: scroll;
    }
  }
  
  .form {
    flex-grow: 1;
  }
  
  .title {
    margin-bottom: 0px;
  
    @media (--viewportLarge) {
      margin-bottom: 0px;
      padding: 1px 0 7px 0;
    }
  }
  
  .aboutTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .subTitle {   
    margin: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  }


.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
  padding-top: 29px;
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.btnBorder {
  border-bottom: 10px;
}

.noBtnBorder {
  border: none;
}

.signupForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.error {
  @apply --marketplaceModalErrorStyles;
}
  