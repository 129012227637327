.locationSearch {
  display: flex;
}

.search {
  background-color: white;
  width: 662px;
  /* border: 2px solid #eaeaea; */
}

@media only screen and (max-width: 768px) {
  .locationSearch {
    display: block;
  }
  .search {
    width: 100% !important;
    border: 1px solid black;
    border-radius: 3px;
  }

  .button {
    width: 100% !important;
  }
}

.button {
  height: 30px !important;
  min-height: 48px !important;
  width: 195px;
  background-color: #1e1e1e !important;
  /* left: 545px;
  top: 517px; */
  border-radius: 3px;
  padding: 8px, 47px, 8px, 47px;
  margin-top: 8px;
}

.modal {
  width: 488px;
  height: 280px;
  background-color: white;
  margin-top: 200px;
  border-radius: 12px;
  padding: 40px 24px 40px 24px;
}

.locationName {
  color: var(--marketplaceColor);
}

.emailMe {
  height: 10px;
  margin-top: 70px;
  border-radius: 3px;
}
