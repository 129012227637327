.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.contactBelowPara {
  margin: 0;
}

.couponCodeBelowPara {
  margin: 0px 0px 10px 0px;
  line-height: 1.6;
}

.formGroup {
  position: relative;
}

.checkIcon {
  background: url('../../assets/check-icon.png') no-repeat;
  background-position: right;
}
