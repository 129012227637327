@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: #383838;
  font-size: 2.7rem !important;
  font-style: normal !important ;
  font-weight: 500 !important;
  line-height: 55px !important;
  letter-spacing: 0px !important;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: #1e1e1e;
  margin: 0 0 32px 0;

  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0px !important;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

@media only screen and (max-width: 600px) {
  .heroMainTitle {
    line-height: 35px !important;
    letter-spacing: 0px !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
  }

  .heroSubTitle {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .heroMainTitle {
    margin-top: 50px;
  }
}

.landingAddress{
  line-height: 24px !important;
  margin: 0px !important;
  color: #383838;
  font-weight: 400;
  font-size: 16px;
}
