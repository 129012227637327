.root {
    flex-grow: 1;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 11px 24px 0 24px;
  }
  
  .form {
    flex-grow: 1;
  }
  
  .title {
    margin-bottom: 19px;
  
    @media (--viewportLarge) {
      margin-bottom: 0px;
      padding: 1px 0 7px 0;
    }
  }
  
  .aboutTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  .subTitle {   
    margin: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  }
  